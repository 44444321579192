main#home{
  padding: 6.8rem 1.1rem 4em;
  @include mq(md){
    padding: 0 calc(1.875rem + 5rem);
  }
}

.circle{
  cursor: pointer;
  height: 5.9375em;
  width: 5.9375em;
  position: absolute;
  background-color: red;
  border-radius: 100%;
  z-index: 99;
  @include mq(md){
    height: 10em;
    width: 10em;
    z-index: 9;
  }
  &.active{
    background-color: white !important;
  }
}

.circle{
  &.top-left{
    left: 1.1rem;
    top: 3.75rem;
  }
  &.top-right{
    right: 1.1rem;
    top: 3.75rem;
  }
  &.bottom-left{
    left: 1.1rem;
    bottom: 1.1rem;
  }
  &.bottom-right{
    right: 1.1rem;
    bottom: 1.1rem;
  }
  @include mq(md){
    &.top-left{
      left: 1.875rem;
      top: calc(2.25rem + 1.875rem);
    }
    &.top-right{
      right: 1.875rem;
      top: calc(2.25rem + 1.875rem);
    }
    &.bottom-left{
      left: 1.875rem;
      bottom: 1.875rem;
    }
    &.bottom-right{
      right: 1.875rem;
      bottom: 1.875rem;
    }
  }
}

.circle{
  &.yellow{
    background: $yellow;
  }
  &.red{
    background: $red;
  }
  &.blue{
    background: $blue;
  }
  &.purple{
    background: $purple;
  }
}