.collection-container{
  padding-top: 5rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  h1{
    margin: 0;
    width: 100%;
  }
}


.collection-container h1:nth-of-type(2){
  @include font(head-kids);
  @include fs(head-kids);
}

.collection-container h1:nth-of-type(3){
  @include font(head-caslon);
  @include fs(head-caslon);
}