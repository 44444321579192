header{
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1.1em;
  z-index: 99;
}
nav{
  width: 100%;
  border-bottom: 0.1em solid $yellow;
  display: flex;
  justify-content: space-between;
  p{
    padding: 0 0.5em;
    &:first-of-type{
      padding-left: 0;
    }
    &:last-of-type{
      padding-right: 0;
    }
  }
  a.active{
    border-top: 0.1em solid;
  }
  @include mq(md){
    max-width: 33.333%;
    min-width: 428px;
    p{
      padding: 0 2em;
    }
  }

}