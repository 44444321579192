// CONFIGURATE DOCUMENT

$fontsize: (
        main: (
                sm: (
                        fs: 20px,
                        lh: 1.4em,
                ),
                md: (
                        fs: 18px,
                        lh: 1.4em,
                ),
        ),
        head-avernus: (
                sm: (
                        fs: 28vw,
                        lh: 1em,
                ),
                md: (
                        fs: 200px,
                        lh: 1em,
                ),
        ),
        head-kids: (
                sm: (
                        fs: 28vw,
                        lh: 1em,
                ),
                md: (
                        fs: 200px,
                        lh: 1em,
                ),
        ),
        head-caslon: (
                sm: (
                        fs: 32vw,
                        lh: 1em,
                ),
                md: (
                        fs: 215px,
                        lh: 1em,
                ),
        ),
);

$margin: (
        sm: 1,
);

:root {
  --base-space: 8px;
  --color-text: black;
  --color-link: black;
  --color-bg: white;
}

// __________________________________________________________ //

// FONTS

$fonts: (
        main: (
                name: neue-haas-grotesk-text-pro-65-medium,
                fallback: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
                withFile: true,
        ),
        head-avernus: (
                name: naraworldmmregular,
                fallback: "Helvetica Neue, sans-serif",
                withFile: true,
        ),
        head-kids: (
                name: churchwardroundsquare,
                fallback: "Helvetica Neue, sans-serif",
                withFile: true,
        ),
        head-caslon: (
                name: caslon-antique,
                fallback: "Helvetica Neue, sans-serif",
                withFile: true,
        ),
);

// __________________________________________________________ //

// BREAKPOINTS

$breakpoints: (
        sm: 0px,
        md: 920px,
);

// __________________________________________________________ //

// TRANSITION

$transition-property-default: all;
$transition-duration-default: 0.2s;
$transition-timing-default: cubic-bezier(0.7, 0, 0.3, 1);
$transition-delay-default: 0s;

// __________________________________________________________ //

// COLORS

$blue: #38b2ff;
$red: #ff3600;
$yellow: #ffd300;
$purple: #19671b;
