*{
  box-sizing: border-box;
  &::-webkit-scrollbar{
    display: none;
  }

}

html, body{
  min-height: auto;
  width: 100%;
  margin: 0;
  padding: 0;
}

main{
  min-height: 100%;
  height: 100%;
  width: 100%;
  display: grid;
  padding: 0 1.1em;
  overflow-y: scroll;
  overflow-x: hidden;
  @include mq(md){
    padding: 0 20%;
  }
}

h1{
  @include font(head-avernus);
  @include fs(head-avernus);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5em 0;
  font-weight: normal;
}

.txt-l{
  font-size: 1em;
  line-height: 1.1em;
  @include mq(md){
    font-size: 2em;
  }
}

.margin-m{
  margin: 4em 0;
}

a.no-dec{
  color: inherit;
  &:hover{
    color: inherit;
    text-decoration: none;
  }
}


.text-container{
  text-align: justify;
  text-align-last: center;
  hyphens: auto;
  p{
    margin-bottom: 0.45em;
  }
  .highlight{
    color: var(--page-underline-color);
  }
  .underline{
    text-decoration: underline;
    text-decoration-color: var(--page-underline-color);
    text-underline-offset: 0.08em;
    text-decoration-thickness: 0.1em;
  }
  a{
    font-size: 1em;
    display: inline-block;
    width: 100%;
    margin-top: 0.2em;
    padding: 0.25em;
    border: 0.08em solid;
    color: var(--page-color);
    border-color: var(--page-underline-color);
    text-align: center;
  }
  a.download{
    text-align: center;
  }
}

a[href^="mailto:"]
{
  font-size: 1em;
  width: 100%;
  margin-top: 0.2em;
  padding: 0.25em;
  border: 0.08em solid;
  color: var(--page-color);
  border-color: var(--page-underline-color);
  text-align: center;
}

a{
  color: var(--page-color);
}


.order a{
  margin-top: 3em;
}

