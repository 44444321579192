.copyrights{
  padding: 3rem 0 1.1rem;
  font-size: 0.5em;
  align-self: end;
  display: flex;
  justify-content: center;
  @include mq(md){
    font-size: 1em;
  }
  p{
    padding: 0 1em;
  }
}