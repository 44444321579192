.collection-title{
  margin-top: 1em;
  position: sticky;
  top: 0.5em;
  margin-bottom: 0.5em;
  @include mq(md){
    margin-top: 0;
    margin-bottom: 0.3em;
  }
}

.collection-subtitle{
  display: none;
  @include mq(md){
    display: block;
    margin-top: 6em;
    margin-bottom: 3.35em;
    color: var(--page-color);
    text-align: center;
  }
}


.text-container + .gallery-section{
  margin-top: 2.8rem;
  margin-bottom: 2.4rem;
}

.text-container.collection-credits{
  p{
    text-align: center;
  }
}

.gallery-section{
  .portrait img{
    padding: 1.1rem;
  }
  @include mq(md){
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 7.1rem;
    margin: 0 -20%;
    margin-bottom: 15rem;
  }
    img{
      object-fit: contain;
      width: 100%;
      object-position: center center;
      @include mq(md){
        height: 100%;
      }
    }
    .grid-1-2{
      grid-column: span 2;
    }
    .grid-2-2{
    grid-column: 2/4;
    }
}

.bottom-title{
  margin-top: 0.35em;
  margin-bottom: 0.5em;
}