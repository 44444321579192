.swiper-container{
  @include mq(md){
    padding: calc(2.25rem + 1.875rem) 0 1.875rem 0;
  }
}

.swiper-container, .swiper-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box !important;
}

.custom-cursor-next {
  cursor: url("data:image/svg+xml,%3Csvg id='a8720a47-cd0b-4f36-970c-320876f87184' data-name='Calque 1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 31.34 15.66'%3E%3Cpolygon points='0 8.44 28.9 8.44 22.45 14.71 23.39 15.66 31.34 7.83 23.39 0 22.45 0.95 28.9 7.24 0 7.24 0 8.44' fill='%231d1d1b'/%3E%3C/svg%3E") 32 32, auto;
}

.custom-cursor-prev {
  cursor: url("data:image/svg+xml,%3Csvg id='e5f37955-52bc-40ea-9938-c29e236b5f55' data-name='Calque 1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 31.34 15.66'%3E%3Cpolygon points='31.34 7.21 2.44 7.21 8.89 0.95 7.95 0 0 7.83 7.95 15.66 8.89 14.71 2.44 8.42 31.34 8.42 31.34 7.21' fill='%231d1d1b'/%3E%3C/svg%3E") 32 32, auto;
}

.swiper-button {
  position: absolute;
  width: 50%;
  height: 100%;
  z-index: 9;

  &.swiper-button-prev {
    left: 0;
    @extend .custom-cursor-prev;
  }

  &.swiper-button-next {
    right: 0;
    @extend .custom-cursor-next;
  }
}

.swiper-slide {
  opacity: 0 !important;
  &.swiper-slide-active{
    opacity: 1 !important;
  }
  &[data-items="1"]{
    display: flex !important;
    justify-content: center;
  }
  &.square[data-items="1"], &.landscape[data-items="1"]{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  &.landscape[data-items="1"] picture{
    @include mq(md){
      width: 70%;
    }
  }
  video{
    @include mq(md){
      width: 70%;
    }
  }

  &[data-items="2"]{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    @include mq(md){
      display: grid !important;
      grid-template-columns: repeat(2,1fr);
    }
    picture{
      width: 100%;
      aspect-ratio: 1/1;
      min-height: auto;
      &:last-of-type{
        display: none;
      }
      img{
        height: 100%;
      }
    }
    @include mq(md){
        picture:last-of-type{
          display: block;
        }
    }
  }
  picture{
    aspect-ratio: 1/1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include mq(md){
      width: 50%;
    }
  }
  img {
    aspect-ratio: 1/1;
    min-width: 100% !important;
    min-height: 100% !important;
    object-fit: cover;
  }
  &.landscape img{
    aspect-ratio: unset;
    object-fit: contain;
  }
}

.text-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;

  h1 {
    line-height: 0.85em;
    margin-bottom: 1em;
    display: block;
  }
}

.tech-slide {
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include mq(sm) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .entry {
    width: 100%;
    border-top: 1px solid;
    margin-top: -1px;
    padding: 0.5em 0 0.52em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    & > * + * {
      margin-left: 1rem;
    }

    p {
      margin-bottom: 0;
    }

    &:first-of-type {
      border-top: none;
    }

    @include mq(sm) {
      width: 66.66666%;
      display: grid;
      padding: 0.295em 0 0.3em;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.navigation {
  margin-top: 3em;
  display: flex;
  justify-content: space-between;
  position: relative;

  p {
    margin-bottom: 0;
    white-space: nowrap;
  }

  .pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    span {
      width: 1em;
      display: inline-block;
      text-align: right;
    }
  }

  @include mq(sm) {
    .pagination {
      position: static;
      left: unset;
      transform: none;
    }
  }
}



.carousel{
  @include mq(md){
    width: calc(100% + 40%);
    margin: 0 -20%;
    height: calc(48vw + 8.1rem);
    padding-bottom: 7.1rem;
  }
  width: 100%;
  height: 100vw;
  display: block;
  overflow: hidden;
  padding-bottom: 1rem;
  margin-top: 1rem;

}


.swiper-container.swiper-article{
  overflow: hidden;
  padding-bottom: 2.875rem;
  .swiper-slide{
    @include mq(md){
      justify-content: center;
    }
  }
  picture{
    display: block;
    width: 100%;
    aspect-ratio: unset;
    img{
      object-fit: contain;
      object-position: center center;
      width: 100%;
      height: unset;
      @include mq(md){
        height: 100%;
      }
      min-height: unset !important;
      min-width: unset !important;
    }
  }
}


.controls{
  display: grid;
  grid-template-columns: min-content max-content min-content;
  align-items: baseline;
  justify-content: center;
}

.swiper-pagination{
  margin-top: 1rem;
  text-align: center;
}
.swiper-pagination .swiper-pagination-bullet-active{
  border: 2px solid;
  border-radius: 100%;
}

.swiper-pagination-bullet{
  height: 1.3em;
  width: 1.3em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-next{
  margin-left: 0.5em;
  cursor: pointer;
}
.swiper-button-prev{
  margin-right: 0.5em;
  cursor: pointer;
}



